.ico {
  width: 100vw;
  height: 100px;
  /* background-color: rgba(0, 0, 0, 0.9); */
  display: flex;
  /* padding: 0 -10px; */
  align-items: center;
  position: fixed;
  z-index: 999;
  left: 0;
  overflow: hiden;
}
.aler {
  background-color: rgba(0, 0, 0, 0.9);
  width: 105px;
  display: flex;
  margin-left: auto;
  margin-top: 30vw;
}
.aler ul {
  list-style: none;
}
.iconlist {
margin: 10px -10px;
}
.iconic-side {
  width: 48px;
  margin-bottom: 20px;
  display: flex;
  margin-left: 2px auto;
  height: 48px;
  cursor: pointer;
}
.iconic-side:hover {
  animation: 2s spin3 alternate;
  color: teal;
  border: 1px soliid white;
}
@media screen and (max-width: 1200px) {
  .aler {
    margin-top: 50vw;
    width: 100px;
  }
  .iconic-side {
    width: 35px;
    height: 36px;
  }
}
@media screen and (max-width: 612px) {
  .aler {
    margin-top: 320px;
  }
}
@media screen and (max-width:457px){
  .aler{
    display: none;
  }
}
@media screen and (max-width: 266px) {
  .aler {
    width: 0px;
  }
  .iconic-side {
    width: 15px;
    height: 15px;
    margin-left: -10px;
  }
}
