.home {
    height: 100vh;
    width: 100vw;
    display: flex;
    background: linear-gradient(rgba(0, 0, 0, .8), #000), url(../assets/393.ef9ac317.gif) ;
}
.homeleft {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.left-img {
    width: 490px;
    height: 490px;
    object-fit: contain;
}
  /* .fgs {
    width: 80%;
    font-size: 16px;
    margin-top: 20px;
    color: #ddd;
    text-align: center;
  }
  .homerleft img {
    width: 70%;
  } */
  .homeright {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
  .topicheader {
    position: relative;
    font-size: 60px;
    color: #ddd;
    font-family: 'Arima Madurai', cursive;
    margin-bottom: 10px;
  }
  .right__sub__title {
    color: #ddd;
    margin-top: -20px;
    text-align: center;
    font-weight: 700;
  }
  .sub_right__para {
    font-size: 16px;
    margin-top: 20px;
    width: 50%;
    color: #ddd;
    text-align: center;
    /* margin-bottom: 40px; */
  }

  .home-main-title {
    text-transform: uppercase;
    font-family: "Raleway", sans-serif;
    font-weight: 800;
    letter-spacing: 1px;
    font-size: 60px;
    color: #f0f8ff;
}

.home-par, .home-short-item {
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    letter-spacing: 1px;
    font-size: 20px;
    width: 70%;
    color: #f0f8ff;
    font-weight: 300;
    text-align: justify;
    margin-top: -40px;
}

.home-short-item {
    font-weight: 200;
    text-align: center;
    margin-top: 5px;
}



.connect-txt {
    border: none;
    padding: 10px;
    width: 395px;
    margin: 10px 14px;
    background: rgba(165, 164, 164, .3);
}


.connect-txtbtn {
    width: 410px;
    color: #f0f8ff;
    background: teal;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 15px;
}
  .inputsect {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .textInput {
    margin: 20px;
    border-color: #d3af37;
    font-size: 20px;
    width: 200px;
  }
  video {
    width: 350px;
    margin-bottom: 30px;
  }
  .OIKNO {
    border: none;
    background-color: #ffc700;
    padding: 10px;
    font-weight: 700;
    color: #fff;
    width: 200px;
    cursor: pointer;
    box-shadow: 0 6px 0 -2px hsl(0deg 0% 98% / 30%);
  }
  @media screen and (max-width: 1233px) {
    .left-img {
      width: 500px;
    }
    .home-main-title {
      font-size: 50px;
    }
    .home {
      height: auto;
    }
    .homeleft {
      margin-bottom: 70px;
      margin-top: 70px;
    }
    .homeright {
      margin-bottom: 70px;
      margin-top: 70px;
    }
  }
  @media screen and (max-width: 998px) {
    .left-img {
      width: 400px;
    }
    .home-main-title {
      font-size: 44px;
    }
  }
  @media screen and (max-width: 844px) {
    .home {
      flex-direction: column;
    }
    .left-img {
      width: 370px;
    }
    .home-main-title {
      margin-top: -30px;
      font-size: 60px;
    }
    .homeleft {
      margin: 0;
    }
    .homeright {
      margin-top: 0;
    }
  }
  @media screen and (max-width: 600px) {
    .left-img {
      width: 300px;
    }
    .home-main-title {
      font-size: 50px;
      margin-top: -40px;
    }
    .home-par {
      width: 90%;
    }
  }
  @media screen and (max-width: 467px) {
    .left-img {
      width: 300px;
    }
    .home-main-title {
      font-size: 40px;
  
      margin-top: -40px;
    }
    .home-par {
      margin-top: 2px;
      width: 80%;
    }
  }
  
  @media screen and (max-width: 456px) {
    .connect-txt {
      width: 250px;
    }
    .home-main-title {
      font-size: 32px;
    }
    .home-par {
      font-size: 16px;
      width: 60%;
    }
    .home-short-item {
      font-size: 15px;
    }
    .connect-txtbtn {
      width: 270px;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 4px;
      font-family: "Poppins", sans-serif;
    }
    .homeleft {
      margin: 0;
    }
    .homeright {
      margin-top: 0;
    }
  }
  @media screen and (max-width: 306px) {
    .home-main-title {
      font-size: 28px;
      margin-top: -100px;
    }
    .home-par {
      width: 100%;
      font-size: 14px;
    }
    .home-short-item {
      font-size: 14px;
    }
    .left-img {
      width: 250px;
    }
  }
  @media screen and (max-width: 276px) {
    .connect-txtbtn {
      width: 230px;
      font-size: 12px;
    }
    .connect-txt {
      width: 210px;
    }
    .home-main-title {
      font-size: 24px;
      text-align: center;
    }
    .connect-txt {
      width: 140px;
    }
    .connect-txtbtn {
      width: 160px;
      border-radius: 0%;
    }
    .home-par {
      font-size: 12px;
      width: 70%;
    }
    .home-short-item {
      font-size: 11px;
    }
    .left-img {
      width: 200px;
    }
    .home {
      height: auto;
    }
    .homeleft {
      margin: 0;
    }
    .homeright {
      margin-top: 0;
    }
  }
  